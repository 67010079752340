import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/Index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index
  },
  {
    path: '/home',
    name: 'home3',
    component: Index
  },
  {
    path: '/home/:local',
    name: 'home4',
    component: Index
  },
  {
    path: '/home/:local/:ref',
    name: 'home2',
    component: Index
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
