import "core-js/modules/web.url-search-params.size.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/es.array.push.js";
import QRCode from 'qrcodejs2';
export default {
  name: 'IndexPage',
  data() {
    return {
      posterImgList: [],
      visible: false,
      posterSelectCode: '',
      height: '100vh',
      local: 'en',
      index: 0
    };
  },
  created() {
    this.height = `${document.documentElement.clientHeight}px`;
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.createPoster();
    });
  },
  methods: {
    changeTab(tab) {
      this.index = tab;
    },
    change() {
      window.onresize = () => {
        this.height = `${document.documentElement.clientHeight}px`;
      };
    },
    gotoUrl() {
      window.open('https://www.stfil.io/#/?ref=' + this.posterSelectCode);
    },
    handleClickPhoto(data) {
      console.log(data);
    },
    handleClickMask(data) {
      console.log(data);
    },
    updateIndex(data) {
      console.log(data);
    },
    handleHide(data) {
      console.log(data);
    },
    myDownloadMethod(item) {
      const url = item.src;
      console.log('url', url);
      const a = document.createElement('a');
      a.download = `ReferralCode-${item.key}`;
      a.href = url;
      a.dispatchEvent(new MouseEvent('click'));
      // 释放 createObjectURL 创建的内存对象（否则以 blob:http 开头的 url 可以到浏览器访问，多次创建内存会不断增大）
      URL.revokeObjectURL(url);
    },
    isEmpty(val) {
      return val === null || val === undefined || val === '' || val.length === 0;
    },
    async createPoster() {
      let ref = this.$route.query.ref;
      if (this.isEmpty(ref)) {
        ref = this.$route.params.ref;
        if (this.isEmpty(ref)) {
          document.location.href = 'https://www.stfil.io/#/';
          return;
        }
      }
      let referralCode = parseInt(ref);
      if (isNaN(referralCode)) {
        referralCode = atob(ref);
        referralCode = parseInt(referralCode);
        if (isNaN(referralCode)) {
          document.location.href = 'https://www.stfil.io/#/';
          return;
        }
      }
      if (referralCode < 1000) {
        document.location.href = 'https://www.stfil.io/#/';
        return;
      }
      let local = this.$route.query.local;
      if (this.isEmpty(local)) {
        local = this.$route.params.local;
        if (this.isEmpty(local)) {
          local = 'en';
        }
      }
      this.local = local;
      this.posterLoading = true;
      this.posterSelectCode = referralCode;
      this.$nextTick(() => {
        this._createPoster_1(local, referralCode);
        // this._createPoster_0(local, referralCode)
      });
    },

    _createPoster_0(local, referralCode) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();
      if (local === 'cn') {
        image.src = require('@/assets/poster/poster_1_cn.jpg');
      } else {
        image.src = require('@/assets/poster/poster_1.jpg');
      }
      const that = this;
      image.onload = function () {
        console.log(image.width, image.height);
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(this, 0, 0, image.width, image.height);
        const text = `https://www.stfil.io/#/?ref=${referralCode.toString()}`;
        const qrImgDiv = document.createElement('div');
        // eslint-disable-next-line no-new
        new QRCode(qrImgDiv, {
          text: text,
          width: 210,
          height: 210,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.Q
        });
        const imgEl = qrImgDiv.getElementsByTagName('img')[0];
        imgEl.onload = function () {
          ctx.rotate(-0.12);
          ctx.drawImage(this, 85, 1460);
          canvas.toBlob(blob => {
            const imgUrl = window.URL.createObjectURL(blob);
            that.posterImgList.push({
              src: imgUrl,
              key: referralCode,
              intro: 'Referral Code: ' + referralCode
            });
            that.posterLoading = false;
            that.visible = true;
            qrImgDiv.remove();
            canvas.remove();
          });
        };
      };
    },
    _createPoster_1(local, referralCode) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = require('@/assets/poster/poster_2.png');
      const that = this;
      image.onload = function () {
        console.log(image.width, image.height);
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(this, 0, 0, image.width, image.height);
        const text = `https://www.stfil.io/#/?ref=${referralCode.toString()}`;
        const qrImgDiv = document.createElement('div');
        // eslint-disable-next-line no-new
        new QRCode(qrImgDiv, {
          text: text,
          width: 300,
          height: 300,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.Q
        });
        const imgEl = qrImgDiv.getElementsByTagName('img')[0];
        imgEl.onload = function () {
          ctx.drawImage(this, 153, 2830);
          canvas.toBlob(blob => {
            const imgUrl = window.URL.createObjectURL(blob);
            that.posterImgList.push({
              src: imgUrl,
              key: referralCode,
              intro: 'Referral Code: ' + referralCode
            });
            that.posterLoading = false;
            that.visible = true;
            qrImgDiv.remove();
            canvas.remove();
          });
        };
      };
    }
  }
};